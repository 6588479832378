import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Randomizer from '../components/randomizer';
import ClientRandomizer from '../components/clientrandomizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import Purchase from '../components/purchase';
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Cover from '../assets/cover.png';
import Kayenta from '../assets/ads/kayenta.jpg';
import SUU from '../assets/ads/SUU_Marketing.jpg';
import Milts from '../assets/ads/MiltsStageStop.jpg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Zoom mdxType="Zoom">
      <h1 {...{
        "id": "utah-shakespeare-festival",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#utah-shakespeare-festival",
          "aria-label": "utah shakespeare festival permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Utah Shakespeare Festival`}</h1>
    </Zoom>
    <blockquote>
      <p parentName="blockquote">{`🔇 We appreciate you silencing your phone.`}</p>
    </blockquote>
    <h4 {...{
      "id": "quick-navigation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#quick-navigation",
        "aria-label": "quick navigation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quick Navigation`}</h4>
    <div className="home-list">
  <Zoom mdxType="Zoom">
    <AniLink cover bg="#023047" className="home-column" href="/shakespeare/pericles" mdxType="AniLink">
      Pericles
    </AniLink>
  </Zoom>
  <Zoom mdxType="Zoom">
    <AniLink cover bg="#023047" className="home-column" href="/shakespeare/richard-III" mdxType="AniLink">
      Richard III
    </AniLink>
  </Zoom>
  <Zoom mdxType="Zoom">
    <AniLink cover bg="#023047" className="home-column" href="/shakespeare/comedy-of-errors" mdxType="AniLink">
      The Comedy of Errors
    </AniLink>
  </Zoom>
  <Zoom mdxType="Zoom">
    <AniLink cover bg="#023047" className="home-column" href="/shakespeare/pirates-of-penzance" mdxType="AniLink">
      The Pirates of Penzance
    </AniLink>
  </Zoom>
  <Zoom mdxType="Zoom">
    <AniLink cover bg="#023047" className="home-column" href="/shakespeare/ragtime" mdxType="AniLink">
      Ragtime
    </AniLink>
  </Zoom>
  <Zoom mdxType="Zoom">
    <AniLink cover bg="#023047" className="home-column" href="/shakespeare/cymbeline" mdxType="AniLink">
      Cymbeline
    </AniLink>
  </Zoom>
  <Zoom mdxType="Zoom">
    <AniLink cover bg="#023047" className="home-column" href="/shakespeare/intimate-apparel" mdxType="AniLink">
      Intimate Apparel
    </AniLink>
  </Zoom>
  <Zoom mdxType="Zoom">
    <AniLink cover bg="#023047" className="home-column" href="/shakespeare/comedy-of-terrors" mdxType="AniLink">
      The Comedy of Terrors
    </AniLink>
  </Zoom>
  <Zoom mdxType="Zoom">
    <AniLink cover bg="#023047" className="home-column" href="/shakespeare/greenshow" mdxType="AniLink">
      The Greenshow
    </AniLink>
  </Zoom>
    </div>
    <hr />
    <small>Ads</small>
    <details>
  <summary>Kayenta Homes</summary>
      <OutboundLink href="http://kayentahomes.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Shakespeare%20festival" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
        <img src={Kayenta} />
      </OutboundLink>
    </details>
    <details>
  <summary>SUU Online</summary>
      <OutboundLink href="http://suu.edu/bgs/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Shakespeare%20festival" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
        <img src={SUU} />
      </OutboundLink>
    </details>
    <details>
  <summary>Milt’s Stage Stop</summary>
      <OutboundLink href="http://miltsstagestop.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Shakespeare%20festival" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
        <img src={Milts} />
      </OutboundLink>
    </details>
    <hr />
    <br />
    <p>{`📱 Please use the menu to explore our new mobile friendly program. or…`}</p>
    <p>{`📖 If you prefer a traditional flipbook experience, as with print publications, `}<a parentName="p" {...{
        "href": "/shakespeare/digital-program"
      }}>{`please click here`}</a></p>
    <br />
    <Purchase mdxType="Purchase" />
    <OutboundLink href="http://kayentahomes.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Shakespeare%20festival" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <img src={Kayenta} id="kayenta" alt="Kayenta homes" />
    </OutboundLink>
    <br />
    <br />
    <OutboundLink href="http://suu.edu/bgs/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Shakespeare%20festival" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <img src={SUU} id="suu" alt="SUU Online" />
    </OutboundLink>
    <br />
    <br />
    <OutboundLink href="http://miltsstagestop.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=Shakespeare%20festival" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <img src={Milts} id="milts" alt="Milt's Stage Stop" />
    </OutboundLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      