import React from 'react';

function clientRandomizer(props) {
  const millsAds = [
    {
      image: '/sponsors/Christmas-Show.jpg',
      link: 'https://tuacahn.org/',
      alt: 'A Christmas Story The Musical at Tuacahn',
      class: 'webFormat',
    },
    {
      image: '/sponsors/Gift_Gallery_final.jpg',
      link: 'https://tuacahn.org/',
      alt: 'Tuacahn Gift Gallery',
      class: 'webFormat',
    },
    {
      image: '/sponsors/Cafe-2021.jpg',
      link: 'https://tuacahn.org/',
      alt: 'Tuacahn Cafe',
      class: 'webFormat',
    },
    {
      image: '/sponsors/Senior-Games-2021.jpg',
      link: 'https://seniorgames.net/',
      alt: 'Huntsman World Senior Games',
      class: 'webFormat',
    },
    {
      image: '/sponsors/TuacahnSatMarket.jpg',
      link: 'https://tuacahn.org/',
      alt: 'Tuacahn Saturday Market',
      class: 'webFormat',
    },
    {
      image: '/sponsors/tuacahn-season.jpg',
      link: 'https://tuacahn.org/',
      alt: 'Tuacahn Season 2021',
      class: 'webFormat',
    },
    {
      image: '/sponsors/Tuacahn_Fall_Concerts_Ad.jpg',
      link: 'https://tuacahn.org/',
      alt: 'Tuacahn Fall Concert Series',
      class: 'webFormat',
    },
    {
      image: '/sponsors/Tuacahn_Sky_West.jpg',
      link: 'https://tuacahn.org/',
      alt: 'Tuacahn Fall Concert Series',
      class: 'webFormat',
    },
  ];

  const randomAd = millsAds[Math.floor(Math.random() * millsAds.length)];

  return (
    <>
      <hr />
      <br />
      <a href={randomAd.link} target="_blank" rel="noopener noreferrer">
        <img
          src={randomAd.image}
          alt={randomAd.alt}
          className={randomAd.class}
        />
      </a>
      <br />
      <br />
      <hr />
    </>
  );
}

export default clientRandomizer;
